import React from 'react';
import { MainLayout } from '../../components/Layout';
import PartnersList from '../../components/Partners/PartnersList';
import { Link, withPrefix } from 'gatsby';
import imgBg from '../../assets/img/home/bg-wave.png';
import imgMobile from '../../assets/img/partners/mobile-quartier-general.png';
import imgIconChat from '../../assets/img/partners/icon-chat.svg';
import imgIconUp from '../../assets/img/partners/icon-up.svg';
import ReferencesSection from '../../components/Partners/ReferencesSection';
import iconMairie17 from '../../assets/img/references/logo_mairie17.png';
import Levallois from '../../assets/img/references/levalois.jpg';

export default () => {
  const referenceList = [
    {
      icon: iconMairie17,
      content:
        'Alacaza accompagne le développement social des territoires sur lesquels leur solution se déploie. En effet, l’outil permet aux habitants, d’un même immeuble et d’un même quartier, de fluidifier et d’enrichir leurs relations grâce à l’intégration des commerçants et des associations dans une application unifiée.',
    },
    {
      icon: Levallois,
      content: '',
    },
  ];

  return (
    <MainLayout
      siteTitle={`Collectivités territoriales - Découvrez nos offres`}
      siteDescription={`Découvrez nos offres déstinées aux collectivités territoriales`}
    >
      <PartnersList selectedPartner="collectivites" />

      <div
        className="bg-wave"
        style={{ backgroundImage: `url(${withPrefix(imgBg)})` }}
      >
        <section className="container mt-5">
          <div className="row">
            <div className="col-6 text-align-center hidden-sm">
              <img
                src={imgMobile}
                alt="Syndic de copropriété"
                className="max-width-400-lg"
              />
            </div>

            <div className="col-4">
              <div className="pl-5 pr-5">
                <div className="roboto font-size-xl bold pt-10">
                  Diffusion d'informations
                </div>
                <div className="text-color-purple-grey pt-2">
                  <ul className="bullets-list">
                    <li>Informez facilement et instantanément vos citoyens</li>
                    <li>
                      Sélectionnez le contenu que vous souhaitez diffuser, du
                      journal municipal à l'agenda culturel ou encore au menu
                      des cantines
                    </li>
                    <li>Gagnez en visibilité</li>
                  </ul>
                </div>

                <div className="roboto font-size-xl bold pt-10">
                  Dialogue citoyen
                </div>
                <div className="text-color-purple-grey pt-2">
                  <ul className="bullets-list">
                    <li>
                      Renouvelez le dialogue entre élus et citoyens à travers
                      notre messagerie et nos outils de démocratie participative
                    </li>
                    <li>
                      Favorisez l'émergence de communautés locales solidaires{' '}
                    </li>
                  </ul>
                </div>

                <div className="roboto font-size-xl bold pt-10 pt-5-md">
                  Animation locale
                </div>
                <div className="text-color-purple-grey pt-2">
                  <ul className="bullets-list">
                    <li>
                      Valorisez la proximité sur votre territoire grâce au
                      travail de nos animateurs locaux
                    </li>
                    <li>
                      Permettez à vos commerces et vos associations locales de
                      gagner en visibilité
                    </li>
                    <li>
                      Notre messagerie de quartier permet d’animer la vie de
                      quartier et de tisser du lien social et économique entre
                      les résidents
                    </li>
                  </ul>
                </div>

                <div className="ml-auto mr-auto pt-15 text-align-center hidden-sm">
                  <Link
                    to="/contact/"
                    state={{
                      contactType: 'partner',
                      contactSubType: 'collectivites',
                    }}
                    className="btn"
                  >
                    Nous contacter
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row hide show-md">
            <div className="col-12 pt-5 pl-6-md pr-6-md text-align-center">
              <img src={imgMobile} alt="Syndic de copropriété" />
            </div>
          </div>

          <div className="row pb-10 hide show-md">
            <div className="col-12 ml-auto mr-auto text-align-center">
              <Link
                to="/contact/"
                state={{
                  contactType: 'partner',
                  contactSubType: 'collectivites',
                }}
                className="btn"
              >
                Nous contacter
              </Link>
            </div>
          </div>
        </section>
      </div>

      <section className="container mt-10 partners-advantages-list">
        <ul className="text-align-center">
          <li className="mb-4">
            <div className="advantage-icon">
              <img src={imgIconChat} alt="Communiquez" />
            </div>
            <div className="pt-3 pl-3 pr-3">
              <strong>Communiquez</strong> efficacement avec vos résidents
            </div>
          </li>
          <li className="mb-4 ml-5">
            <div className="advantage-icon">
              <img src={imgIconUp} alt="Valorisez" />
            </div>
            <div className="pt-3 pl-3 pr-3">
              <strong>Valorisez</strong> votre économie locale
            </div>
          </li>
        </ul>
      </section>

      <ReferencesSection referenceList={referenceList} />
    </MainLayout>
  );
};
